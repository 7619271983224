import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export function headerNavReveal() {
  gsap.registerPlugin(ScrollTrigger);

  const siteHeader = document.getElementById('site-header');
  const siteHeaderNav = document.querySelector('#site-header-nav');
  const siteHeaderNavLinks = document.querySelectorAll('#site-header-nav a');
  const logoLetters = document.querySelector('#site-logo-letters');
  const container = document.querySelector('#site-header-container-nav-bg');
  const hamburger = document.querySelector('#site-header-hamburger');
  const line1 = document.querySelector('.hamburger__inner--1');
  const line2 = document.querySelector('.hamburger__inner--2');
  const line3 = document.querySelector('.hamburger__inner--3');

  const isScreenDesktop = window.matchMedia('(min-width:1280px)');

  let lastScrollPosition = 0;
  let lastScrollDirection = '';
  let lastScrollIsTop = false;
  let isInitial = true;
  let isMenuOpen = false;
  let currentViewport = null;
  let lastViewport = null;

  // Check if siteHeaderNav exists, if not return
  if (!siteHeaderNav) {
    return;
  }

  // Function to play animation based on menu visibility
  function playAnimation(isMenuWillBeActive) {
    isMenuOpen = isMenuWillBeActive;

    const tl = gsap.timeline({
      paused: false
    });

    tl.to(container, {
      opacity: isMenuWillBeActive ? 1 : 0,
      width: isMenuWillBeActive ? '100%' : 44,
      duration: 0.4
    })
      .to(
        siteHeaderNav,
        {
          autoAlpha: isMenuWillBeActive ? 1 : 0,
          x: isMenuWillBeActive ? 0 : 30,
          duration: 0.4
        },
        isMenuWillBeActive ? '-=0.4' : '-=0.4'
      )
      .to(
        hamburger,
        {
          autoAlpha: isMenuWillBeActive ? 0 : 1,
          duration: 0.4
        },
        isMenuWillBeActive ? '-=0.4' : '-=0.4'
      )
      .to(
        line1,
        {
          opacity: isMenuWillBeActive ? 0 : 1,
          width: isMenuWillBeActive ? 0 : 26,
          duration: 0.4
        },
        isMenuWillBeActive ? '' : '-=0.3'
      )
      .to(
        line2,
        {
          opacity: isMenuWillBeActive ? 0 : 1,
          width: isMenuWillBeActive ? 0 : 26,
          duration: 0.4
        },
        isMenuWillBeActive ? '' : '-=0.2'
      )
      .to(
        line3,
        {
          opacity: isMenuWillBeActive ? 0 : 1,
          width: isMenuWillBeActive ? 0 : 26,
          duration: 0.4,
          onComplete: function () {
            gsap.set(hamburger, { visibility: isMenuWillBeActive ? 'hidden' : 'visible' });
          }
        },
        isMenuWillBeActive ? '' : '-=0.2'
      );
  }

  // Function to handle scroll events
  function handleScroll() {
    const currentScrollPosition = window.scrollY;
    const isScrolledToTop = currentScrollPosition === 0;
    const currentScrollDirection = currentScrollPosition > lastScrollPosition ? 'down' : 'up';
    const theme = siteHeader.getAttribute('data-theme');

    // Change background color based on scroll position
    gsap.to(container, {
      backgroundColor: isScrolledToTop ? 'rgb(217, 217, 214, 0.3)' : '#D9D9D6',
      duration: 0.4
    });

    // Change color of navigation links based on scroll and theme
    gsap.to(siteHeaderNavLinks, {
      color: isScrolledToTop && theme === 'heroDark' ? '#fff' : '#13294B',
      duration: 0
    });

    // Fade in/out logo letters based on scroll position
    gsap.to(logoLetters, {
      opacity: isScrolledToTop ? 1 : 0,
      xPercent: isScrolledToTop ? 0 : -20,
      duration: 0.4
    });

    if (currentViewport == 'desktop') {

      if (isInitial && !isScrolledToTop) {

        playAnimation(false);
      }
      else if(isScrolledToTop) {
        playAnimation(true);
      }
      else if(currentScrollDirection === 'down' && lastScrollDirection === 'up') {
        playAnimation(false);
      }

      isInitial = false;

    } else {
      playAnimation(false);
    }

    lastScrollPosition = currentScrollPosition;
    lastScrollDirection = currentScrollDirection;
    lastScrollIsTop = isScrolledToTop;
  }

  // Function to check the current viewport size
  function checkViewport() {
    if (isScreenDesktop.matches) {
      currentViewport = 'desktop';

      if (currentViewport != lastViewport) {
        handleScroll();
      }
    } else {
      currentViewport = 'mobile';

      if (currentViewport != lastViewport) {
        playAnimation(false);
      }
    }

    lastViewport = currentViewport;
  }

  // Initialization
  // --------------------------------------------
  isScreenDesktop.addEventListener('change', () => {
    checkViewport();
  });
  checkViewport();

  window.addEventListener('scroll', handleScroll);

  hamburger.addEventListener('click', (e) => {
    e.preventDefault();

    if (currentViewport === 'desktop') {
      playAnimation(true);
      siteHeaderNavLinks.forEach((link) => {
        link.setAttribute('tabindex', '0');
      });
      siteHeaderNavLinks[0].focus();
    } else {
      document.body.dispatchEvent(new Event('siteMobileNav:show', { bubbles: true }));
    }
  });
}
